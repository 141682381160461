'use strict';

angular.module('enervexSalesappApp').controller('DesignsCtrl', function($scope, $state, $stateParams, DesignService, Account, Job, User, Auth, Design, FileUploader, Flash, Property, Util) {
	$scope.jobLabel = Util.jobLabel;
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	$scope.showImportDesign = true;
	$scope.showExportSchedule = true
	$scope.showExportDesign = true
	$scope.configs = Property.configs();
	$scope.isAccountOwner = function() {
		return Auth.isAccountOwner($scope.account)
	}
	Account.get({
		id: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.account = res;
	});
	Job.get({
		id: $stateParams.jobId,
		accountId: $stateParams.accountId
	}).$promise.then(function(job){
		$scope.job = job;
		$scope.permissions = Auth.jobPermissions($scope.job, $scope.configs);
	});
	getDesigns();
	function getDesigns() {
		DesignService.fetchDesigns().$promise.then(function(designs){
			$scope.designs = _.sortBy(designs, function(design) {
				return design.name && design.name.toLowerCase()
			})
		});
	}
	$scope.removeDesign = function(design){
		DesignService.deleteDesign(design).$promise.then(function(res){
			getDesigns();
		});
	}
	$scope.showDesignFormArea = function(){
		$state.go("design", {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			designId: "new"
		}, {
			reload: true,
			inherit: false
		})
	}
	$scope.export = function(design) {
		var url = "/api/accounts/" + $stateParams.accountId + "/jobs/" + $stateParams.jobId + "/designs/" + design._id + "/export";
		window.location.href = url;
	}
	$scope.uploader = new FileUploader({});
	$scope.import = function() {
		_.each($scope.uploader.queue, function(data){
			var fd = new FormData();
			fd.append('name', data._file.name);
			fd.append('asset', data._file);
			$scope.uploadResponse = false;
			Flash.clear()
			Design.import({
				accountId: $scope.accountId,
				jobId: $scope.jobId,

			},fd).$promise.then(function(res){
				var message = "Successfully imported design &quot;"+res.name + "&quot";
				Flash.create('info', '<strong>Success!</strong> ' + message, 0, {}, true);
				// $scope.uploadResponse = res
				$scope.uploader.clearQueue()
				getDesigns()
			}).catch(function(e){
				var message = "";
				if (e.data ) {
					message = message + e.data.message
				} else {
					message = message + e
				}
				Flash.create('danger', '<strong>Failure!</strong> ' + message, 0, {}, true);
				$scope.uploader.clearQueue()
			})
		});
	}
	$scope.exportSchedule = function() {
		var url = "/api/accounts/" + $scope.accountId + "/jobs/" + $scope.jobId + "/schedules";
		window.location.href = url;
	}
});
